<template>
  <div class="ticket-date">
    <div class="ticket-date__date-wrapper">
      <Heading class="ticket-date__title" level="4">
        {{ $t('dashboard.date') }}
      </Heading>
      <div class="ticket-date__date">
        <button
          v-for="item in datesList"
          :key="item"
          class="ticket-date__btn"
          :class="{ active: item === selectedDate }"
          @click="selectDate(item)"
        >
          {{ item }}
        </button>
      </div>
    </div>

    <div class="ticket-date__time">
      <Heading level="4" class="">{{ $t('dashboard.hour') }}</Heading>
      <div class="ticket-date__time-wrapper">
        <template v-if="timeSlots">
          <div
            v-for="item in timeGroup"
            :key="item.period"
            class="ticket-date__time-inner"
          >
            <div class="ticket-date__range">{{ item.period }}</div>
            <button
              v-for="slot in item.slots"
              :key="slot.interval"
              class="ticket-date__item"
              :class="{ active: slot.interval === selectedTime }"
              type="button"
              :disabled="!slot.available || slot.interval === selectedTime"
              @click="selectTimeInterval(slot)"
            >
              {{ slot.interval }}
            </button>
          </div>
        </template>
        <Loader v-else />
      </div>
    </div>

    <div class="ticket-date__message-wrapper">
      <Heading
        level="3"
        class="ticket-date__message"
        :class="{ disabled: !isSelectedDateTime }"
      >
        <template v-if="isSelectedDateTime">
          <span class="ticket-date__message-date">
            <svg-icon name="date"></svg-icon>
            <span>{{ selectedDate }}</span>
          </span>
          <span class="ticket-date__message-slot">
            <svg-icon name="time"></svg-icon>
            <span>{{ selectedTime }}</span>
          </span>
        </template>
        <template v-else>
          {{ $t('buy_ticket.choose_date_and_time') }}
        </template>
      </Heading>

      <div class="ticket-date__message__btns">
        <router-link :to="{ name: 'newTicketInfo' }">
          <ButtonBase size="md-bg" color="white">{{
            $t('buy_ticket.back')
          }}</ButtonBase>
        </router-link>
        <ButtonBase
          size="md-bg"
          color="blue"
          :disabled="!isSelectedDateTime"
          @click.native="selectDateTimeHandler"
        >
          {{ $t('form.next') }}
        </ButtonBase>
      </div>
    </div>
  </div>
</template>

<script>
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';
import { getCorrectTimeValue } from '@/library/dateFormat';

import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    ButtonBase,
    Loader,
  },
  data() {
    return {
      currentDaysNumber: 7,
      timePeriods: ['00-08', '08-16', '16-24'],
      timeSlots: null,
      typeAuto: getFromLS('new-ticket').type_auto,
      selectedDate: '',
      selectedTime: '',
    };
  },
  computed: {
    datesList() {
      let d = new Date();
      let days = [];

      for (let i = 0; i < this.currentDaysNumber; i++) {
        let newDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + i);

        let res = `${getCorrectTimeValue(newDate.getDate())}`;
        res += `.${getCorrectTimeValue(newDate.getMonth() + 1)}`;
        res += `.${newDate.getFullYear()}`;

        days.push(res);
      }
      return days;
    },
    timeGroup() {
      return this.timePeriods.map((item, idx) => {
        const slotsGroup = this.timeSlots.slice(idx * 8, (idx + 1) * 8);

        return {
          period: item,
          slots: [...slotsGroup],
        };
      });
    },
    isSelectedDateTime() {
      return !!this.selectedDate.length && !!this.selectedTime.length;
    },
    formattedSelectedDate() {
      const date = this.selectedDate.split('.').reverse().join('-');
      const timeStart = this.selectedTime.slice(0, 5);

      return `${date} ${timeStart}`;
    },
    ticketInfoDateTime() {
      return {
        datetime: this.formattedSelectedDate,
        time_interval: this.selectedTime,
      };
    },
  },
  created() {
    this.selectedDate = this.datesList[0];
  },
  mounted() {
    this.fetchTimeSlots();
  },
  methods: {
    async fetchTimeSlots() {
      const res = await this.$store.dispatch('getTimeSlots', {
        date: this.formattedSelectedDate,
        typeAuto: this.typeAuto,
      });
      this.timeSlots = res;
    },
    resetTime() {
      this.timeSlots = null;
      this.selectedTime = '';
    },
    selectDate(date) {
      this.selectedDate = date;
      this.resetTime();

      this.fetchTimeSlots();
    },
    async selectTimeInterval(slot) {
      this.selectedTime = slot.interval;

      await this.$store.dispatch('executeHoldSlot', {
        datetime: this.formattedSelectedDate,
        typeAuto: this.typeAuto,
      });
    },
    selectDateTimeHandler() {
      this.updateNewTicketInLS();
      this.goToPaymentChoice();
    },
    updateNewTicketInLS() {
      if (isLSHasItem('new-ticket')) {
        const updatedNewTicket = {
          ...getFromLS('new-ticket'),
          ...this.ticketInfoDateTime,
        };

        setToLS('new-ticket', updatedNewTicket);
      }
    },
    goToPaymentChoice() {
      this.$router.push({ name: 'newTicketPaymentChoice' });
    },
  },
};
</script>
<style lang="sass" scoped>
.ticket-date__date-wrapper
  display: flex
  justify-content: space-between
  padding: 32px 0
  border-bottom: 1px solid var(--primary-color-light-grey-2)

.ticket-date__title
  margin: 0

.ticket-date__date
  display: grid
  width: 83%
  gap: 16px
  grid-template-columns: 1fr 1fr 1fr 1fr
  grid-template-rows: 1fr 1fr
  font-weight: 600
  font-size: 18px

.ticket-date__time
  padding-bottom: 24px
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 1199px)
    padding-bottom: 16px

.ticket-date__time-wrapper
  min-height: 480px

.ticket-date .loader
  display: flex
  min-height: inherit

.ticket-date__time-inner
  display: grid
  width: 100%
  gap: 16px
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr
  grid-template-rows: 1fr 1fr

.ticket-date__btn
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 64px
  border: solid 1px var(--primary-color-dark-grey-3)
  border-radius: var(--primary-border-radius)
  box-shadow: var(--primary-border-shadow-1)
  cursor: pointer

  &.active
    background: var(--btn-color-blue)
    color: white

.ticket-date__btn:hover
  border-color: var(--btn-color-blue)

.ticket-date__item
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 64px
  cursor: pointer
  border-radius: var(--primary-border-radius)
  border: solid 1px var(--primary-color-dark-blue)
  box-shadow: var(--primary-border-shadow-2)
  font-weight: 600
  font-size: 18px

  &[disabled]
    background-color: var(--primary-color-light-grey-6)
    border-color: var(--primary-color-light-grey-5)
    color: var(--primary-color-dark-grey-2)
    cursor: default
    position: relative

    &:before
      background: url(/images/cross-lines.svg) no-repeat 50%
      background-size: contain
      position: absolute
      content: ""
      width: 100%
      height: 100%
      top: 0
      left: 0

.ticket-date__item.active
  background: var(--btn-color-blue)
  color: var(--primary-color-white)

.ticket-date__item[disabled].active
  &:before
    display: none

.ticket-date__item:not([disabled]):hover
  border-color: var(--btn-color-blue)

.ticket-date__btn,
.ticket-date__item
  background-color: var(--primary-color-white)

.ticket-date__range
  height: 64px
  display: flex
  align-items: center
  grid-row: 1 / 4
  color: var(--primary-color-dark-grey-1)


.ticket-date__message-wrapper
  display: flex
  flex-direction: column
  align-items: center

.ticket-date__message
  display: flex
  align-items: center
  justify-content: center
  gap: 44px

  @media screen and (max-width: 1199px)
    font-size: 40px

.ticket-date__message
  &.disabled
    color :#939DAC

.ticket-date__message-date,
.ticket-date__message-slot
  display: flex
  align-items: center
  gap: 12px

.ticket-date__message-date
  position: relative

.ticket-date__message-date:after
  position: absolute
  content: '',
  width: 1px
  height: 40px
  top: 50%
  right: -22px
  transform: translateY(-50%)
  background-color: var(--primary-color-light-grey-2)


.ticket-date__message__btns
  display: flex
  gap: 32px
  margin-bottom: 70px
</style>
